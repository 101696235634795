import React from 'react';
import './Home.css';

function NotFound() {
  return (
    <main className='main'>
      <div className='center'>
        <img src='/c1.gif' className='icone'/>
        <h1 className='h'>Erreur 404</h1> 
      </div>
    </main>
  );
}

export default NotFound;