import './Home.css';
import React from 'react';

function Home() {
  return (
    <main className='main'>
      <div className='center'>
        <img src='/unavailable.png' className='icone'/>
        <h1 className='h'>Indisponible</h1> 
        <p className='p'>Nous sommes vraiment désolés !</p>
      </div>
    </main>
  );
}

export default Home;